import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Do you need support with using any of our apps?</h4>
        <p>
          If you are experiencing problems with using any of our apps and or you want to get in touch with the developer, 
            please kindly use the "Contact Us" form on this page to send us your message.
        </p>

          
        <ul>
            {/*
          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              123 Stree New York City , United States Of America 750065.
            </span>
          </li>
          */}
            
          {/* End li */}

            {/*
          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">support@domain.com</span>
          </li>
          */}
            
          {/* End li */}

            {/*
          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">+044 9696 9696 3636</span>
          </li>
          */}
            
          {/* End li */}
        </ul>
          
          
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
