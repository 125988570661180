import React from "react";

const AwardContnet = [
  {
    img: "christian-podcast",
    awardName: "Christian Podcasts: U.S.A",
    awardFor: "Access Audio & Video Podcasts, Audio Bibles, Daily Devotional, e.t.c",
    link: "https://play.google.com/store/apps/details?id=uk.blueapps.christian.podcasts.usa",
    awardIcon: "Android",
  },
  {
    img: "christian-radio",
    awardName: "Christian Radio: U.S.A",
    awardFor: "Listen to Christian radio stations from U.S.A and around the world.",
    link: "https://play.google.com/store/apps/details?id=uk.blueapps.christian.radio.usa",
    awardIcon: "Android",
  },
  {
    img: "esv-bible-study",
    awardName: "ESV Bible Study Plans",
    awardFor: "Listen to the whole Bible using various ESV Bible study Plans.",
    link: "https://play.google.com/store/apps/details?id=uk.blueapps.esv.bible.study.plans",
    awardIcon: "Android",
  },
    
    {
    img: "joyce",
    awardName: "BlueCast: Joyce Meyer",
    awardFor: "Listen to Sermons & Podcasts, and read Daily Devotional by Joyce Meyer.",
    link: "https://play.google.com/store/apps/details?id=uk.blueapps.bluecast.joyce.meyer",
    awardIcon: "Android",
  },
    
    {
    img: "oyedepo",
    awardName: "BlueCast: David Oyedepo",
    awardFor: "Listen to Sermons, Audio Books & Audio Bible, and read E-Books by David Oyedepo.",
    link: "https://play.google.com/store/apps/details?id=uk.blueapps.david.oyedepo",
    awardIcon: "Android",
  },
    /***
    {
    img: "a3",
    awardName: "Best Learner Award",
    awardFor: "Site of the day",
    link: "#",
    awardIcon: "Android",
  }, ***/
];

const Awards = () => {
  return (
    <>
      <div className="row">
        {AwardContnet.map((val, i) => (
          <div className="col-lg-4 m-15px-tb" key={i}>
                  
            <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
            <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <img src={`img/award/${val.img}.png`} alt="award" />
              </div>
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p><p className="float-right">{val.awardIcon}</p>
              </div>
              </div>
            </a>
                  
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
