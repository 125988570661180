import React from "react";
import Social from "../Social";
import Testimonials from "../testimonial/Testimonial";
import Services from "../service/Service";
import Awards from "../award/Awards";

const AboutTwo = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-lg-4">
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/about-me-2.jpg" alt="about" />
                  </div>
                  
                    {/*
                  <Social /> */}
                  {/* End social icon */}
                    
                    
                </div>
                {/* End img */}
                <div className="info">
                  <p>Graphic Design &amp; App Development</p>
                  <h3>BlueApps UK</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div className="col-lg-7 ml-auto">
              <div className="about-info">
                  {/*}
                <div className="title">
                  <h3>About BlueApps</h3>
                </div>
                  */}
                <div className="about-text">
                {/*  <p>
                    BlueApps UK is a mobile app development outfit {/* established by Maxwell Air-Obarogie. 
                      The outfit is */}{/*  focused primarily on developing native applications for Android 
                      and iOS mobile devices.
                  </p> */}
                    
                    <p>
                    BlueApps UK is a professional outfit specialized in graphics &amp; web design, and mobile app development for android and iOS devices.
                  </p>
                    
                  <p>
                    Our apps are unique and professionally designed to deliver 
                      inspiring and educational content to users through beautifully 
                      designed interfaces that are easy to navigate. 
                      We incorporate different functionalities and activities into our mobile apps in order 
                      to deliver certain services and content to the users of our apps. These include Radio players, Podcast players, 
                      Video players, News / RSS feed readers, Audio / Music players, E-Book readers, 
                      Audio book players, and lots more. 
                    </p>
                    
                    <p>  
                  We constantly work hard to improve on the quality of our apps and to introduce new features that meets with the trending standards in the mobile app industry.
                    </p>
                    
                    <p>
                        We have developed and published several mobile apps, many of which are free for users to download from the different App stores. 
                    </p>
                </div>
                  {/* <div className="info-list">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Name: </label>
                          <span>Nairobi Gadot</span>
                        </li>
                        <li>
                          <label>Birthday: </label>
                          <span>4th April 1994</span>
                        </li>
                        <li>
                          <label>Age: </label>
                          <span>26 years</span>
                        </li>
                        <li>
                          <label>Address: </label>
                          <span>San Francisco</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Phone: </label>
                          <span>(+38) 469 2344 2364</span>
                        </li>
                        <li>
                          <label>Email: </label>
                          <span>info@domainname.com</span>
                        </li>
                        <li>
                          <label>Skype: </label>
                          <span>nairob.40</span>
                        </li>
                        <li>
                          <label>Freelance: </label>
                          <span>Available</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
            
            
            
          {/* End separated */}
            
          {/*  
          <div className="title">
            <h3>What I do?</h3>
          </div>
          <Services /> 
          */}
            
          {/* End .row */}

          {/* separated */}
            
            {/*
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div> 
            */}
            
          {/* End separated */}
            
            
            
            
            
            

          <div className="titleXXXXXXXXXXXXX">
            <h3>Published Apps</h3>
          </div>
          <Awards />
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

            
            {/*
          <div className="title">
            <h3>Testimonials.</h3>
          </div>
          <Testimonials />
            */}
            
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default AboutTwo;
