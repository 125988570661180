import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('contact_blueapps', 'template_9s79iax', form.current, 'xpFgeLflOV-sW3wzK')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      
    //  alert ("Thank you. Your message has been sent");
      document.getElementById('fieldName').value = ''; // this is used to clear the input field
      document.getElementById('fieldEmail').value = ''; // this is used to clear the input field
      document.getElementById('fieldSubject').value = ''; // this is used to clear the input field
      document.getElementById('fieldMessage').value = ''; // this is used to clear the input field
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
                
              <input id="fieldName" type="text" className="form-control theme-light" placeholder="Full name" name="user_name" />
                
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
                
              <input id="fieldEmail" type="email" className="form-control theme-light" placeholder="Email address" name="user_email" />
              
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
                
              <input id="fieldSubject" type="text" className="form-control theme-light" placeholder="Subject" name="subject" />
              
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group mb-3">
                
              <textarea id="fieldMessage" rows="4" className="form-control theme-light" placeholder="Message" name="message" ></textarea>
                
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
                
              {/*  <input className="px-btn px-btn-white" type="submit" value="Send" /> */}
              <button className="px-btn px-btn-white">Send Message</button>
                
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
